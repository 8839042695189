<template>
  <div class="info">
    <div class="box">
      <div class="header">
        <img src="../assets/image/zhibaiTitle.png" alt="" />
        <span>新一代企业官网DIY自定义智能摆放系统</span>
      </div>
      <div class="form">
        <div>
          <div class="title">
            <div class="per" @click="$router.back(-1)">< 上一步</div>
            创建账号
          </div>
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
            <!-- <el-form-item label="类型" class="title-name">
              <div class="text">智摆DIY官网建站</div>
            </el-form-item> -->

            <el-form-item label="登录账号" prop="username" class="label">
              <el-input v-model="ruleForm.username" maxlength="20" placeholder="输入账号"></el-input>
            </el-form-item>
            <el-form-item label="密码" prop="password" class="label label-password" :verify="{ minLen: 6, maxLen: 16, typeOptions: ['字母|数字'] }">
              <el-input v-model="ruleForm.password" type="password" show-password maxlength="16" placeholder="输入密码"></el-input>
            </el-form-item>
            <el-form-item label="" class="label-tip">
              <div class="tip">长度为6-16位字符并且必须存在英文或数字</div>
            </el-form-item>
            <el-form-item label="姓名" prop="name" class="label">
              <el-input v-model="ruleForm.name" maxlength="10" placeholder="输入姓名"></el-input>
            </el-form-item>
            <el-form-item label="手机号" prop="phone" class="label">
              <el-input v-model="ruleForm.phone" type="number" maxlength="11" placeholder="输入手机号"></el-input>
            </el-form-item>
            <!-- <el-form-item label="验证码" prop="code" class="label">
              <el-input v-model="ruleForm.code" placeholder="请输入验证码">
                <el-button slot="append">验证</el-button>
              </el-input>
            </el-form-item> -->
            <el-form-item label="邮箱" prop="email" class="label">
              <el-input v-model="ruleForm.email" placeholder="输入邮箱"></el-input>
            </el-form-item>
            <el-form-item label="" prop="agree" class="label xy">
              <el-checkbox v-model="ruleForm.agree">接受并同意</el-checkbox>
              <span>《用户服务协议》</span>
            </el-form-item>
            <el-form-item class="button">
              <el-button type="primary" @click="submitForm()">创建账号</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="copyright">
        <div class="flex row-center">
          <div class="flex row-center">
            <span>运营企业管理平台</span>
          </div>
          <div class="flex row-center ml20">
            <span>Copyright © 2022 运营管理</span>
            <span class="ml20" @click="go">研发技术支持：济文研发中心</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from '@/utils/cloud'

export default {
  data() {
    return {
      ruleForm: {
        username: '',
        name: '',
        phone: '',
        password: '',
        email: '',
        agree: ''
      },
      rules: {
        username: [{ required: true, message: '输入账号', trigger: 'change' }],
        agree: [{ type: 'boolean', required: true, message: '请阅读并同意条款', trigger: 'change' }],
        password: [{ required: true, message: '输入密码', trigger: 'change' }]
        // phone: [{ required: true, message: '输入手机号', trigger: 'blur' }],
        // code: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
        // email: [{ type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }]
      }
    }
  },
  methods: {
    go() {
      window.location.href = 'http://www.jiwenkeji.com/'
    },
    submitForm(formName) {
      this.$refs.ruleForm.validate(async res => {
        if (res) {
          const { username, name, phone, email, password } = this.ruleForm
          const response = await http.POST('/jw-admin', {
            module: 'admin',
            operation: 'addUser',
            username,
            password,
            email,
            name,
            phone
          })
          if (response.status === 1) {
            this.$message.success('注册成功')
            this.$router.go(-1)
          } else {
            this.$message.error(response.msg)
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.info {
  width: 100vw;
  height: 100vh;
  background: #f9f9f9;
  .box {
    width: 1300px;
    margin: 0 auto;
    .copyright {
      display: flex;
      justify-content: center;
      padding: 30px 0;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #999999;
    }
    .header {
      height: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 30px;
      > img {
        width: 250px;
        height: 100px;
        vertical-align: middle;
      }
      > span {
        font-size: 20px;
        margin-top: 16px;
        font-size: 16px;
        color: #203775;
        display: inline-block;
        font-family: PingFangSC-Regular, PingFang SC;
      }
    }
    .form {
      width: 94%;
      max-width: 649px;
      margin: 0 auto;
      font-size: 0px;
      padding-bottom: 30px;
      background: #fff;
      box-shadow: 0px 0px 10px rgb(0 0 0 / 6%);
      border-radius: 10px;
      > div {
        width: auto;
        margin: 40px 110px 0px 75px;
        .title {
          font-size: 30px;
          line-height: 30px;
          padding-top: 60px;
          font-weight: bold;
          color: #343434;
          text-align: center;
          position: relative;
          .per {
            position: absolute;
            left: 0px;
            height: 30px;
            line-height: 30px;
            font-size: 14px;
            color: #999;
            font-weight: normal;
            cursor: pointer;
          }
        }
        .demo-ruleForm {
          margin-top: 15px;
          padding: 20px 0;
          .title-name {
            ::v-deep .el-form-item__label {
              font-weight: bold;
              font-size: 16px;
              color: #343434;
            }
            .text {
              font-weight: bold;
              font-size: 16px;
              color: #343434;
            }
          }
          .label {
            ::v-deep .el-form-item__label {
              font-size: 16px;
              color: #333;
            }
          }
          .label-tip {
            margin-bottom: 15px;
            .tip {
              font-size: 14px;
              color: #999;
            }
          }
          .label-password {
            margin-bottom: 5px;
          }
          .xy {
            color: #48bdaa;
            cursor: pointer;
            font-size: 16px;
            margin-bottom: 0;
            .el-checkbox {
              margin-right: 10px;
              zoom: 100%;
              ::v-deep .el-checkbox__inner {
                width: 16px;
                height: 16px;
              }
            }
          }
          .button {
            padding: 15px 0px;
            .el-button {
              width: 100%;
              height: 50px;
              border: none;
              cursor: pointer;
              color: #fff;
              font-size: 18px;
              letter-spacing: 5px;
              border-radius: 4px;
            }
          }
        }
      }
    }
  }
}
</style>
